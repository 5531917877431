import permissions from '@/helpers/permissions'

export default [
  {
    path: '/libraries',
    component: () => import(/* webpackChunkName: "libraries" */ '@/layouts/Main.vue'),
    children: [
      {
        name: 'Libraries',
        path: '',
        component: () => import(/* webpackChunkName: "libraries/index" */ '@/views/Libraries/index.vue'),
        meta: { permissions: [permissions.LIBRARY.READ] }
      },
      {
        name: 'LibrariesCreate',
        path: 'create',
        component: () => import(/* webpackChunkName: "libraries/create" */ '@/views/Libraries/create.vue'),
        meta: { permissions: [permissions.LIBRARY.READ] }
      }
    ]
  }
]
