import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

export default {
  namespaced: true,
  state: {
    token: '',
    user: '',
    error: '',
    interface: {
      navbar: []
    },
    done: true,
    success: true
  },
  getters: {
    state: (state: sessionState) => state,
    isAuthenticated: (state: sessionState) => !!state.token,
    isCommonUser: (state: sessionState) => {
      if (state.user) {
        return state.user.roles?.find((role: Role) => role.name === 'Usuário')
      } else {
        return false
      }
    },
    isEventPresident: (state: sessionState) => {
      if (state.user?.roles !== undefined) {
        return state.user.roles.some((role: Role) => ['Presidente Eventos', 'Vice-Presidente Eventos'].includes(role.name))
      } else {
        return false
      }
    },
    isCECPresident: (state: sessionState) => {
      if (state.user?.roles !== undefined) {
        return state.user.roles.some((role: Role) => ['Vice-Presidente CEC', 'Presidente CEC'].includes(role.name))
      } else {
        return false
      }
    },
    getUploadedTEOT: (state: sessionState) => {
      return state.user.teot ? state.user.teot : ''
    },
    hasSomeRole: (state: sessionState) => (roles: Array<string>) => {
      if (state.user?.roles !== undefined) {
        return state.user.roles.some((role: Role) => roles.includes(role.name))
      }
      return false
    },
    // TODO: this is possibly gambicode
    hasSomeServiceRole: (state: sessionState) => (roles: Array<string>) => {
      if (state.user?.serviceRoles !== undefined) {
        return state.user.serviceRoles.some((serviceRole: any) => {
          return roles.includes(serviceRole.roleName)
        })
      }
      return false
    },
    isChiefWithoutService: (state: sessionState) => {
      return state.user.profile?.some((profile: any) => profile.metaKey === 'isChiefWithoutService')
    },
    isNewSelfSubscriber: (state: sessionState) => {
      return state.user.profile?.find((profile: any) => profile.metaKey === 'is_new_self_subscriber')
    },
    isPublicBuyer: (state: sessionState) => {
      return state.user.profile?.find((profile: any) => profile.metaKey === 'is_public_buyer')
    },
    getSubscription: (state: sessionState) => {
      const eventId = state.user.profile?.find((profile: any) => profile.metaKey === 'event_id')?.metaValue
      const subscriptionId = state.user.profile?.find((profile: any) => profile.metaKey === 'subscription_id')?.metaValue

      return { eventId, subscriptionId }
    },
    getCPF: (state: sessionState) => {
      return state.user.profile?.find((profile: any) => profile.metaKey === 'cpf')?.metaValue
    },
    getNavbarItems: (state: sessionState) => {
      return state.interface.navbar
    }
  },
  mutations: {
    DOING (state: sessionState) {
      state.done = false
      state.error = ''
    },
    DONE (state: sessionState) {
      state.done = true
    },
    SUCCESS (state: sessionState) {
      state.success = true
      state.error = ''
    },
    ERROR (state: sessionState, error: string) {
      state.success = false
      state.error = error
    },
    SET_INTERFACE (state: sessionState, interfaceObject: any) {
      state.interface = interfaceObject
    },
    SET_TEOT (state: sessionState, teot: string | boolean) {
      state.user.teot = teot
    },
    LOGIN (state: sessionState, user: any) {
      state.user = user
      state.token = user.token.token
    },
    LOGOUT (state: sessionState) {
      state.token = ''
      state.user = {}
      state.interface = { navbar: [] }
    }
  },
  actions: {
    async login ({ dispatch, commit }: {dispatch: Function; commit: Function}, login: login) {
      commit('DOING')
      await axios.post('session/login', login)
        .then(async (response) => {
          const data = camelCaseKeys(response.data.data, { deep: true })
          const token = data.token.token
          if (token !== undefined) {
            axios.defaults.headers.common['Access-Token'] = token
            commit('LOGIN', data)
            commit('SET_INTERFACE', data.interface)

            await dispatch('userPermissions/fetchPermissions', data.id, { root: true })
            await dispatch('servicesPermissions/setPermissionsFromSession', data.serviceRoles, { root: true })
            await dispatch('eventsPermissions/setPermissionsFromSession', data.eventRoles, { root: true })
            await dispatch('eventsPermissions/setStudyPermissions', data.eventStudyConditions, { root: true })

            commit('SUCCESS')
          } else {
            commit('ERROR', 'Token not found in the response. Please contact system admin.')
          }
        }).catch(error => {
          if (error.response) {
            console.log(error.response)
            commit('ERROR', error.response.data.error)
          } else {
            commit('ERROR', error)
          }
        })
        .finally(() => commit('DONE'))
    },
    async logout ({ commit }: {commit: Function}) {
      commit('DOING')
      await axios.post('session/logout')
        .then(() => {
          commit('userPermissions/SET_PERMISSIONS', [], { root: true })
          commit('servicesPermissions/SET_PERMISSIONS', [], { root: true })
          commit('takeExam/SET_EXAM', null, { root: true })
          commit('takeExam/SET_QUESTION', null, { root: true })
          commit('LOGOUT')
        }).catch(error => {
          console.log(error.response)
          commit('LOGOUT')
        }).finally(() => {
          delete axios.defaults.headers.common['Access-Token']
          commit('SUCCESS')
          commit('DONE')
        })
    },
    setTEOT ({ commit }: {commit: Function}, teot: string | boolean) {
      commit('SET_TEOT', teot)
    }
  }
}
