import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['session/isAuthenticated']
  const hasPermission = store.getters['userPermissions/hasPermission']
  const hasPermissionOnAnyService = store.getters['servicesPermissions/hasPermissionOnAnyService']

  // check if the route is public
  if (to.matched.some(record => record.meta.publicRoute)) {
    next()
    return
  }

  // check if user is Authenticated
  if (isAuthenticated) {
    // check if its trying to access an access route
    if (to.matched.some(record => record.meta.accessRoute)) {
      if (to.name === 'EventVoucher') {
        store.dispatch('session/logout')
        next()
      } else {
        // redirect to Home
        next({ name: 'Home' })
      }
    } else {
      const routePermissions = to.meta.permissions
      // check if route has permissions meta_key
      if (routePermissions) {
        const isUserPermitted = routePermissions.some((permission: string) => hasPermission(permission) || hasPermissionOnAnyService(permission))
        // check if user has the permission
        if (isUserPermitted) {
          next()
        } else { // if it doesnt have the permssion
          const message = { message: 'Acesso negado', isSuccess: false }
          store.dispatch('flashMessage/setMessage', message)
          next({ name: 'Home' })
        }
      } else { // if no permission is required for the route
        next()
      }
    }
  } else { // if user is not Authenticated
    // check if user isnt going to accessRoute
    if (!to.matched.some(record => record.meta.accessRoute)) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath }
      })
    } else { // if user is going to accessRoute
      next()
    }
  }
})

export default router
