import { RouteConfig } from 'vue-router'

import permissions from '@/helpers/permissions'
import store from '@/store/index'

const uuidRegex = '\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b'
const vistoryHash = '\\b[0-9A-z]{32}\\b'
const activityDay = 'today|yesterday'
const personType = 'specialist|secretary|visitor|chief'
const hasServicePermission = store.getters['servicesPermissions/hasPermission']

const serviceGuard = (id: string, permission: string, next: Function) => {
  if (hasServicePermission(id, permission)) {
    next()
  } else {
    const message = { message: 'Acesso negado', isSuccess: false }
    store.dispatch('flashMessage/setMessage', message)
    next({ name: 'Home' })
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/services',
    component: () => import(/* webpackChunkName: "services" */ '@/layouts/Main.vue'),
    children: [
      {
        path: `:id(${uuidRegex})/vistory`,
        name: 'ServiceVistory',
        component: () => import(/* webpackChunkName: "services/vistory" */ '@/views/Services/Vistory/index.vue')
      },
      {
        path: `:serviceId(${uuidRegex})/service-user/:serviceUserId(${uuidRegex})/register-activity/:day(${activityDay})`,
        name: 'RegisterActivity',
        component: () => import(/* webpackChunkName: "services/register-activity" */ '@/views/Services/RegisterActivity.vue')
      },
      {
        path: `:id(${uuidRegex})/add-person/visitor`,
        name: 'AddVisitorToService',
        component: () => import(/* webpackChunkName: "services/addPerson/visitor" */ '@/views/Services/AddPerson/visitor.vue'),
        beforeEnter: (to, from, next) => serviceGuard(to.params.id, permissions.SERVICEVISITOR.CREATE, next)
      },
      {
        path: `:id(${uuidRegex})/add-person/specialist`,
        name: 'AddSpecialistToService',
        component: () => import(/* webpackChunkName: "services/addPerson/specialists" */ '@/views/Services/AddPerson/specialist.vue'),
        beforeEnter: (to, from, next) => serviceGuard(to.params.id, permissions.SERVICEVISITOR.CREATE, next)
      },
      {
        path: `:id(${uuidRegex})/add-person/secretary`,
        name: 'AddSecretaryToService',
        component: () => import(/* webpackChunkName: "services/addPerson/secretary" */ '@/views/Services/AddPerson/secretary.vue')
      },
      {
        path: `:id(${uuidRegex})/add-person/:person(${personType})/create`,
        name: 'UsersToServiceCreate',
        component: () => import(/* webpackChunkName: "services/addPerson/person/create" */ '@/views/Services/AddPerson/createUserToService.vue')
      },
      {
        path: `:id(${uuidRegex})/chief`,
        name: 'UpdateServiceChief',
        component: () => import(/* webpackChunkName: "services/addPerson/chief" */ '@/views/Services/AddPerson/serviceChief.vue'),
        beforeEnter: (to, from, next) => serviceGuard(to.params.id, permissions.SERVICECHIEF.UPDATE, next)
      },
      {
        path: 'create',
        name: 'ServicesCreate',
        component: () => import(/* webpackChunkName: "services/create" */ '@/views/Services/create.vue'),
        meta: { permissions: [permissions.SERVICE.CREATE] }
      },
      {
        path: 'public-create',
        name: 'ServicesPublicCreate',
        component: () => import(/* webpackChunkName: "services/public-create" */ '@/views/Services/publicCreate.vue')
      },
      {
        path: `:id(${uuidRegex})/edit`,
        name: 'ServicesEdit',
        component: () => import(/* webpackChunkName: "services/edit" */ '@/views/Services/edit.vue'),
        beforeEnter: (to, from, next) => serviceGuard(to.params.id, permissions.SERVICEVISITOR.CREATE, next)
      },
      {
        path: '',
        name: 'Services',
        component: () => import(/* webpackChunkName: "services/index" */ '@/views/Services/index.vue'),
        meta: { permissions: [permissions.SERVICE.READ] }
      },
      {
        path: `:serviceId(${uuidRegex})/user/:serviceUserId(${uuidRegex})/diary`,
        name: 'ServiceUserDiary',
        component: () => import(/* webpackChunkName: "services/user/diary" */ '@/views/Services/ServiceUser/diary.vue'),
        beforeEnter: (to, from, next) => serviceGuard(to.params.serviceId, permissions.SERVICESPECIALIST.READ, next)
      },
      {
        path: 'messages',
        name: 'ServiceMessages',
        component: () => import(/* webpackChunkName: "services/messages/index" */ '@/views/Services/Messages/index.vue'),
        meta: { permissions: [permissions.SERVICE.READ] }
      },
      {
        path: `messages/:id(${uuidRegex})`,
        name: 'ServiceMessagesShow',
        component: () => import(/* webpackChunkName: "services/messages/show" */ '@/views/Services/Messages/show.vue'),
        meta: { permissions: [permissions.SERVICE.READ] }
      },
      {
        path: 'messages/create',
        name: 'ServiceMessageCreate',
        component: () => import(/* webpackChunkName: "services/messages/create" */ '@/views/Services/Messages/create.vue'),
        meta: { permissions: [permissions.SERVICEMESSAGE.CREATE] }
      },
      {
        path: 'surveys',
        name: 'ServiceSurveys',
        component: () => import(/* webpackChunkName: "services/surveys/index" */ '@/views/Services/Surveys/index.vue'),
        meta: { permissions: [permissions.SERVICESURVEY.READ] }
      },
      {
        path: `surveys/:id(${uuidRegex})`,
        name: 'ServiceSurveyShow',
        component: () => import(/* webpackChunkName: "services/surveys/show" */ '@/views/Services/Surveys/show.vue'),
        meta: { permissions: [permissions.SERVICESURVEY.READ] }
      },
      {
        path: `:serviceId(${uuidRegex})/surveys/:surveyId(${uuidRegex})/answer`,
        name: 'ServiceSurveyAnswer',
        component: () => import(/* webpackChunkName: "services/surveys/show" */ '@/views/Services/Surveys/Answer.vue')
      },
      {
        path: 'surveys/create',
        name: 'ServiceSurveyCreate',
        component: () => import(/* webpackChunkName: "services/surveys/create" */ '@/views/Services/Surveys/create.vue'),
        meta: { permissions: [permissions.SERVICESURVEY.CREATE] }
      },
      {
        path: `:id(${uuidRegex})`,
        name: 'ServicesShow',
        component: () => import(/* webpackChunkName: "services/show" */ '@/views/Services/show.vue'),
        meta: { permissions: [permissions.SERVICE.READ] }
      }
    ]
  },
  {
    path: `/services/vistory/:hash(${vistoryHash})`,
    name: 'ServiceVistoryShow',
    component: () => import(/* webpackChunkName: "services/vistory/hash" */ '@/views/Services/Vistory/show.vue')
  }
]

export default routes
