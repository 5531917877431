type permissionsState = {
  done: boolean;
  error: string;
  success: boolean;
  services: Array<ServiceWithPermissions>;
}

const state: permissionsState = {
  services: [],
  error: '',
  done: true,
  success: true
}

function convertServiceRolesFromSession (sessionServiceRoles: Array<ServiceRoleWithPermissions>) {
  return sessionServiceRoles.map((serviceRole: ServiceRoleWithPermissions) => {
    const service = {
      id: serviceRole.serviceId,
      permissions: serviceRole.rolePermissions,
      serviceUserId: serviceRole.serviceUserId
    }

    return service
  })
}
export default {
  namespaced: true,
  state,
  getters: {
    hasPermission: (state: permissionsState, getters: Function, rootState: Function, rootGetters: Record<string, Function>) => (serviceId: string, permissionName: string) => {
      if (!rootGetters['session/isCECPresident']) {
        const service = state.services.find(service => serviceId === service.id)

        if (!service) {
          return false
        } else {
          return Boolean(service.permissions.find(permission => permissionName === permission.name))
        }
      } else {
        return rootGetters['userPermissions/hasPermission'](permissionName)
      }
    },
    hasPermissionOnAnyService: (state: permissionsState) => (permissionName: string) => {
      return state.services.some(service => {
        return service.permissions.some(permission => permissionName === permission.name)
      })
    },
    getServiceUserId: (state: permissionsState) => (serviceId: string) => {
      const service = state.services.find(service => serviceId === service.id)

      if (!service) {
        return false
      } else {
        return service.serviceUserId
      }
    }
  },
  mutations: {
    DOING (state: permissionsState) {
      state.done = false
      state.error = ''
    },
    DONE (state: permissionsState) {
      state.done = true
    },
    ERROR (state: permissionsState, error: string) {
      state.success = false
      state.error = error
    },
    SET_PERMISSIONS (state: permissionsState, services: Array<ServiceWithPermissions>) {
      state.services = services
    },
    ADD_PERMISSIONS (state: permissionsState, service: ServiceWithPermissions) {
      const stateServices = state.services.find(stateService => stateService.id === service.id)

      if (!stateServices) {
        state.services.push(service)
      }
    }
  },
  actions: {
    setPermissionsFromSession ({ commit }: {commit: Function}, servicesRoles: Array<ServiceRoleWithPermissions>) {
      const services = convertServiceRolesFromSession(servicesRoles)
      commit('SET_PERMISSIONS', services)
    }
  }
}
