export default Object.freeze({
  CEC: {
    READ: 'Read:CEC'
  },
  EXAMSCHEDULE: {
    CREATE: 'Create:ExamSchedule',
    READ: 'Read:ExamSchedule',
    DELETE: 'Delete:ExamSchedule',
    UPDATE: 'Update:ExamSchedule'
  },
  EXAMDRAFT: {
    READ: 'Read:ExamDraft',
    UPDATE: 'Update:ExamDraft',
    CREATE: 'Create:ExamDraft',
    DELETE: 'Delete:ExamDraft'
  },
  EXAM: {
    CREATE: 'Create:Exam',
    TAKE: 'Take:Exam',
    UPDATE: 'Update:Exam',
    READ: 'Read:Exam',
    DELETE: 'Delete:Exam'
  },
  LIBRARY: {
    READ: 'Read:Library',
    UPDATE: 'Update:Library',
    CREATE: 'Create:Library',
    DELETE: 'Delete:Library'
  },
  MONITOR: {
    READ: 'Read:Monitor'
  },
  PERMISSION: {
    READ: 'Read:Permission'
  },
  QUESTIONDRAFT: {
    UPDATE: 'Update:QuestionDraft',
    CREATE: 'Create:QuestionDraft',
    DELETE: 'Delete:QuestionDraft',
    READ: 'Read:QuestionDraft'
  },
  QUESTION: {
    CREATE: 'Create:Question',
    DELETE: 'Delete:Question',
    READ: 'Read:Question',
    UPDATE: 'Update:Question'
  },
  ROLE: {
    READ: 'Read:Role'
  },
  SERVICEUSER: {
    DELETE: 'Delete:ServiceUser',
    UPDATE: 'Update:ServiceUser',
    READ: 'Read:ServiceUser',
    CREATE: 'Create:ServiceUser'
  },
  SERVICEMESSAGE: {
    TAKE: 'Take:ServiceMessage',
    CREATE: 'Create:ServiceMessage',
    READ: 'Read:ServiceMessage',
    DELETE: 'Delete:ServiceMessage',
    UPDATE: 'Update:ServiceMessage'
  },
  SERVICECHIEF: {
    DELETE: 'Delete:ServiceChief',
    UPDATE: 'Update:ServiceChief',
    READ: 'Read:ServiceChief',
    CREATE: 'Create:ServiceChief'
  },
  SERVICECONTACT: {
    CREATE: 'Create:ServiceContact'
  },
  SERVICEEVALUATION: {
    UPDATE: 'Update:ServiceEvaluation',
    DELETE: 'Delete:ServiceEvaluation',
    TAKE: 'Take:ServiceEvaluation',
    CREATE: 'Create:ServiceEvaluation',
    READ: 'Read:ServiceEvaluation'
  },
  SERVICESECRETARY: {
    READ: 'Read:ServiceSecretary',
    UPDATE: 'Update:ServiceSecretary',
    DELETE: 'Delete:ServiceSecretary',
    CREATE: 'Create:ServiceSecretary'
  },
  SERVICESPECIALIST: {
    READ: 'Read:ServiceSpecialist',
    UPDATE: 'Update:ServiceSpecialist',
    DELETE: 'Delete:ServiceSpecialist',
    CREATE: 'Create:ServiceSpecialist'
  },
  SERVICESURVEY: {
    TAKE: 'Take:ServiceSurvey',
    CREATE: 'Create:ServiceSurvey',
    READ: 'Read:ServiceSurvey',
    DELETE: 'Delete:ServiceSurvey',
    UPDATE: 'Update:ServiceSurvey'
  },
  SERVICEVISITOR: {
    READ: 'Read:ServiceVisitor',
    CREATE: 'Create:ServiceVisitor',
    DELETE: 'Delete:ServiceVisitor',
    UPDATE: 'Update:ServiceVisitor'
  },
  SERVICE: {
    READ: 'Read:Service',
    CREATE: 'Create:Service',
    DELETE: 'Delete:Service',
    UPDATE: 'Update:Service',
    REPORT: 'Report:Service'
  },
  SERVICEREGION: {
    UPDATE: 'Update:ServiceRegion',
    READ: 'Read:ServiceRegion',
    CREATE: 'Create:ServiceRegion',
    DELETE: 'Delete:ServiceRegion'
  },
  SERVICETAB: {
    READ: 'Read:ServiceTab'
  },
  SPECIALISTDIARY: {
    READ: 'Read:SpecialistDiary',
    CREATE: 'Create:SpecialistDiary',
    UPDATE: 'Update:SpecialistDiary',
    DELETE: 'Delete:SpecialistDiary'
  },
  TOPIC: {
    CREATE: 'Create:Topic',
    UPDATE: 'Update:Topic',
    READ: 'Read:Topic',
    DELETE: 'Delete:Topic'
  },
  USER: {
    CREATE: 'Create:User',
    READ: 'Read:User',
    DELETE: 'Delete:User',
    UPDATE: 'Update:User'
  },
  USERASSOCIATION: {
    DELETE: 'Delete:UserAssociation',
    UPDATE: 'Update:UserAssociation',
    CREATE: 'Create:UserAssociation',
    READ: 'Read:UserAssociation'
  },
  USERPERMISSION: {
    DELETE: 'Delete:UserPermission',
    UPDATE: 'Update:UserPermission',
    CREATE: 'Create:UserPermission',
    READ: 'Read:UserPermission'
  },
  USERROLE: {
    CREATE: 'Create:UserRole',
    READ: 'Read:UserRole',
    UPDATE: 'Update:UserRole',
    DELETE: 'Delete:UserRole'
  },
  VISITORDIARY: {
    READ: 'Read:VisitorDiary',
    UPDATE: 'Update:VisitorDiary',
    CREATE: 'Create:VisitorDiary',
    DELETE: 'Delete:VisitorDiary'
  },
  VISITOR: {
    DELETE: 'Delete:Visitor',
    READ: 'Read:Visitor',
    CREATE: 'Create:Visitor',
    UPDATE: 'Update:Visitor'
  },
  // Event Permissions
  EVENT: {
    READ: 'Read:Event',
    CREATE: 'Create:Event',
    UPDATE: 'Update:Event',
    DELETE: 'Delete:Event'
  },
  EVENTROOM: {
    READ: 'Read:EventRoom',
    CREATE: 'Create:EventRoom',
    UPDATE: 'Update:EventRoom',
    DELETE: 'Delete:EventRoom',
    REPORT: 'Report:EventRoom'
  },
  EVENTTOPIC: {
    READ: 'Read:EventTopic',
    CREATE: 'Create:EventTopic',
    UPDATE: 'Update:EventTopic',
    DELETE: 'Delete:EventTopic',
    REPORT: 'Report:EventTopic'
  },
  EVENTACTIVITYMODULE: {
    READ: 'Read:EventActivityModule',
    CREATE: 'Create:EventActivityModule',
    UPDATE: 'Update:EventActivityModule',
    DELETE: 'Delete:EventActivityModule'
  },
  EVENTSUBSCRIPTION: {
    READ: 'Read:EventSubscription',
    CREATE: 'Create:EventSubscription',
    UPDATE: 'Update:EventSubscription',
    DELETE: 'Delete:EventSubscription'
  },
  EVENTSUBSCRIPTIONTYPE: {
    READ: 'Read:EventSubscriptionType',
    CREATE: 'Create:EventSubscriptionType',
    UPDATE: 'Update:EventSubscriptionType',
    DELETE: 'Delete:EventSubscriptionType'
  },
  EVENTSUBSCRIPTIONTYPERULE: {
    READ: 'Read:EventSubscriptionTypeRule',
    CREATE: 'Create:EventSubscriptionTypeRule',
    UPDATE: 'Update:EventSubscriptionTypeRule',
    DELETE: 'Delete:EventSubscriptionTypeRule'
  },
  EVENTSUBSCRIPTIONTYPEITEM: {
    READ: 'Read:EventSubscriptionTypeItem',
    CREATE: 'Create:EventSubscriptionTypeItem',
    UPDATE: 'Update:EventSubscriptionTypeItem',
    DELETE: 'Delete:EventSubscriptionTypeItem'
  },
  EVENTACTIVITY: {
    CREATE: 'Create:EventActivity',
    READ: 'Read:EventActivity',
    UPDATE: 'Update:EventActivity',
    DELETE: 'Delete:EventActivity'
  },
  EVENTACTIVITYTOPIC: {
    CREATE: 'Create:EventActivityTopic',
    READ: 'Read:EventActivityTopic',
    UPDATE: 'Update:EventActivityTopic',
    DELETE: 'Delete:EventActivityTopic'
  },
  EVENTACTIVITYATTACHMENT: {
    CREATE: 'Create:EventActivityAttachment',
    READ: 'Read:EventActivityAttachment',
    UPDATE: 'Update:EventActivityAttachment',
    DELETE: 'Delete:EventActivityAttachment'
  },
  EVENTSPEAKER: {
    CREATE: 'Create:EventSpeaker',
    READ: 'Read:EventSpeaker',
    UPDATE: 'Update:EventSpeaker',
    DELETE: 'Delete:EventSpeaker'
  },
  EVENTSTAFF: {
    CREATE: 'Create:EventStaff',
    READ: 'Read:EventStaff',
    UPDATE: 'Update:EventStaff',
    DELETE: 'Delete:EventStaff'
  },
  EVENTSPONSOR: {
    CREATE: 'Create:EventSponsor',
    READ: 'Read:EventSponsor',
    UPDATE: 'Update:EventSponsor',
    DELETE: 'Delete:EventSponsor'
  },
  EVENTSUBSCRIBER: {
    CREATE: 'Create:EventSubscriber',
    READ: 'Read:EventSubscriber',
    UPDATE: 'Update:EventSubscriber',
    DELETE: 'Delete:EventSubscriber'
  },
  EVENTSECRETARY: {
    CREATE: 'Create:EventSecretary',
    READ: 'Read:EventSecretary',
    UPDATE: 'Update:EventSecretary',
    DELETE: 'Delete:EventSecretary'
  },
  EVENTTAB: {
    READ: 'Read:EventTab'
  },
  EVENTREPORT: {
    READ: 'Read:EventReport'
  },
  EVENTSURVEY: {
    CREATE: 'Create:EventSurvey',
    READ: 'Read:EventSurvey',
    UPDATE: 'Update:EventSurvey',
    DELETE: 'Delete:EventSurvey',
    ANSWER: 'Answer:EventSurvey'
  },
  EVENTSTUDY: {
    CREATE: 'Create:EventStudy',
    READ: 'Read:EventStudy',
    UPDATE: 'Update:EventStudy',
    DELETE: 'Delete:EventStudy'
  },
  EVENTSTUDYRULE: {
    CREATE: 'Create:EventStudyRule',
    READ: 'Read:EventStudyRule',
    UPDATE: 'Update:EventStudyRule',
    DELETE: 'Delete:EventStudyRule'
  },
  EVENTSTUDYTOPIC: {
    CREATE: 'Create:EventStudyTopic',
    READ: 'Read:EventStudyTopic',
    UPDATE: 'Update:EventStudyTopic',
    DELETE: 'Delete:EventStudyTopic'
  },
  EVENTSTUDYAUTHOR: {
    CREATE: 'Create:EventStudyAuthor',
    READ: 'Read:EventStudyAuthor',
    UPDATE: 'Update:EventStudyAuthor',
    DELETE: 'Delete:EventStudyAuthor'
  },
  EVENTSTUDYREVIEW: {
    CREATE: 'Create:EventStudyReview',
    READ: 'Read:EventStudyReview',
    UPDATE: 'Update:EventStudyReview',
    DELETE: 'Delete:EventStudyReview'
  },
  EVENTSTUDYREVIEWSTATUS: {
    UPDATE: 'Update:EventStudyReviewStatus'
  },
  EVENTSTUDYREVIEWER: {
    CREATE: 'Create:EventStudyReviewer',
    READ: 'Read:EventStudyReviewer',
    UPDATE: 'Update:EventStudyReviewer',
    DELETE: 'Delete:EventStudyReviewer'
  },
  EVENTSTUDYREVIEWGRADE: {
    CREATE: 'Create:EventStudyReviewGrade',
    READ: 'Read:EventStudyReviewGrade',
    UPDATE: 'Update:EventStudyReviewGrade',
    DELETE: 'Delete:EventStudyReviewGrade'
  },
  EVENTSTUDYREVIEWCRITERIA: {
    CREATE: 'Create:EventStudyReviewCriteria',
    READ: 'Read:EventStudyReviewCriteria',
    UPDATE: 'Update:EventStudyReviewCriteria',
    DELETE: 'Delete:EventStudyReviewCriteria'
  },
  EVENTORGANIZER: {
    CREATE: 'Create:EventOrganizer',
    READ: 'Read:EventOrganizer',
    UPDATE: 'Update:EventOrganizer',
    DELETE: 'Delete:EventOrganizer'
  },
  EVENTMESSAGE: {
    CREATE: 'Create:EventMessage',
    READ: 'Read:EventMessage',
    UPDATE: 'Update:EventMessage',
    DELETE: 'Delete:EventMessage'
  },
  EVENTSUBSCRIBERDIARY: {
    CREATE: 'Create:EventSubscriberDiary',
    READ: 'Read:EventSubscriberDiary',
    UPDATE: 'Update:EventSubscriberDiary',
    DELETE: 'Delete:EventSubscriberDiary'
  },
  EVENTUSER: {
    CREATE: 'Create:EventUser',
    READ: 'Read:EventUser',
    UPDATE: 'Update:EventUser',
    DELETE: 'Delete:EventUser'
  }
})
