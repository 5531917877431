import { RouteConfig } from 'vue-router'

const uuidRegex = '\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b'

const routes: Array<RouteConfig> = [
  {
    path: '/medical',
    component: () => import(/* webpackChunkName: "medical" */ '@/layouts/Main.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "medical/layout" */ '@/layouts/MedicalMenu.vue'),
        redirect: { name: 'MedicalPatients' },
        children: [
          {
            path: 'patients',
            name: 'MedicalPatients',
            component: () => import(/* webpackChunkName: "medical/patients/index" */ '@/views/Medical/Patients/index.vue')
          },
          {
            path: 'forms',
            name: 'MedicalForms',
            component: () => import(/* webpackChunkName: "medical/forms/index" */ '@/views/Medical/Forms/index.vue')
          },
          {
            path: 'instructions',
            name: 'MedicalInstructions',
            component: () => import(/* webpackChunkName: "medical/instructions/index" */ '@/views/Medical/Instructions/index.vue')
          }
        ]
      },
      {
        path: 'patients',
        component: () => import(/* webpackChunkName: "medical/patients/layout" */ '@/layouts/Medical/PatientsLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'MedicalPatientCreate',
            component: () => import(/* webpackChunkName: "medical/patients/create" */ '@/views/Medical/Patients/create.vue')
          }
        ]
      },
      {
        path: `patients/:patientId(${uuidRegex})`,
        component: () => import(/* webpackChunkName: "medical/patients/layout" */ '@/layouts/Medical/Patients/DetailsLayout.vue'),
        children: [
          {
            path: 'registration-form',
            name: 'MedicalPatientRegistrationForm',
            component: () => import(/* webpackChunkName: "medical/patients/registration-form" */ '@/views/Medical/Patients/RegistrationForm.vue')
          },
          {
            path: 'evaluations',
            name: 'MedicalPatientEvaluations',
            component: () => import(/* webpackChunkName: "medical/patients/evaluations/index" */ '@/views/Medical/Patients/Evaluations/index.vue')
          },
          {
            path: 'documents',
            name: 'MedicalPatientDocuments',
            component: () => import(/* webpackChunkName: "medical/patients/documents/index" */ '@/views/Medical/Patients/Documents/index.vue')
          }
        ]
      },
      {
        path: `patients/:patientId(${uuidRegex})/evaluations/:formId(${uuidRegex})`,
        component: () => import(/* webpackChunkName: "medical/patients/evaluations/layout" */ '@/layouts/Medical/Patients/EvaluationsLayout.vue'),
        children: [
          {
            path: 'show',
            name: 'MedicalPatientEvaluationShow',
            component: () => import(/* webpackChunkName: "medical/patients/evaluations/index" */ '@/views/Medical/Patients/Evaluations/show.vue')
          },
          {
            path: 'edit',
            name: 'MedicalPatientEvaluationEdit',
            component: () => import(/* webpackChunkName: "medical/patients/evaluations/index" */ '@/views/Medical/Patients/Evaluations/edit.vue')
          }
        ]
      },
      {
        path: `patients/:patientId(${uuidRegex})/documents`,
        component: () => import(/* webpackChunkName: "medical/patients/layout" */ '@/layouts/Medical/Patients/DocumentsLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'MedicalPatientDocumentCreate',
            component: () => import(/* webpackChunkName: "medical/patients/documents/index" */ '@/views/Medical/Patients/Documents/create.vue')
          }
        ]
      },
      {
        path: 'forms',
        component: () => import(/* webpackChunkName: "medical/forms/layout" */ '@/layouts/Medical/FormsLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'MedicalFormCreate',
            component: () => import(/* webpackChunkName: "medical/forms/create" */ '@/views/Medical/Forms/create.vue')
          },
          {
            path: 'templates',
            name: 'MedicalFormTemplates',
            component: () => import(/* webpackChunkName: "medical/forms/create" */ '@/views/Medical/Forms/Templates/index.vue')
          },
          {
            path: `:id(${uuidRegex})/edit`,
            name: 'MedicalFormEdit',
            component: () => import(/* webpackChunkName: "medical/forms/edit" */ '@/views/Medical/Forms/edit.vue')
          },
          {
            path: `:id(${uuidRegex})/show`,
            name: 'MedicalFormShow',
            component: () => import(/* webpackChunkName: "medical/forms/edit" */ '@/views/Medical/Forms/show.vue')
          }
        ]
      },
      {
        path: `forms/:formId(${uuidRegex})/questions`,
        component: () => import(/* webpackChunkName: "medical/forms/questions/layout" */ '@/layouts/Medical/Forms/QuestionsLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'MedicalFormQuestionCreate',
            component: () => import(/* webpackChunkName: "medical/forms/questions/create" */ '@/views/Medical/Forms/Questions/create.vue')
          },
          {
            path: `:questionId(${uuidRegex})/edit`,
            name: 'MedicalFormQuestionEdit',
            component: () => import(/* webpackChunkName: "medical/forms/questions/edit" */ '@/views/Medical/Forms/Questions/edit.vue')
          }
        ]
      },
      {
        path: 'instructions',
        component: () => import(/* webpackChunkName: "medical/instructions/layout" */ '@/layouts/Medical/InstructionsLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'MedicalInstructionCreate',
            component: () => import(/* webpackChunkName: "medical/instructions/create" */ '@/views/Medical/Instructions/create.vue')
          },
          {
            path: `:id(${uuidRegex})/edit`,
            name: 'MedicalInstructionEdit',
            component: () => import(/* webpackChunkName: "medical/instructions/edit" */ '@/views/Medical/Instructions/edit.vue')
          }
        ]
      }
    ]
  },
  {
    path: `/public/medical/instruction/:id(${uuidRegex})/show`,
    name: 'MedicalInstructionShow',
    component: () => import(/* webpackChunkName: "public/medical/instructions/show" */ '@/views/Medical/Instructions/show.vue'),
    meta: { publicRoute: true }
  },
  {
    path: `/public/medical/patient/:patientId(${uuidRegex})/form/:formId(${uuidRegex})`,
    name: 'MedicalFormAnswer',
    component: () => import(/* webpackChunkName: "public/medical/form/answer" */ '@/views/Medical/Forms/answer.vue'),
    meta: { publicRoute: true }
  },
  {
    path: '/public/medical/form/thank-you',
    name: 'MedicalFormAnswerThankYou',
    component: () => import(/* webpackChunkName: "public/medical/form/thank-you" */ '@/views/Medical/Forms/ThankYouAnswer.vue'),
    meta: { publicRoute: true }
  }
]

export default routes
