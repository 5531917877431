const accessTokenRegex = '[0-9A-z]{64}'
const inviteTokenRegex = '.{244}'
const uuidRegex = '\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b'

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: { accessRoute: true }
  },
  {
    path: '/recover-access',
    name: 'RecoverAccess',
    component: () => import(/* webpackChunkName: "recover-access/index" */ '@/views/RecoverAccess/index.vue'),
    meta: { accessRoute: true }
  },
  {
    path: `/recover-access/:token(${accessTokenRegex})`,
    name: 'RecoverAccessNew',
    component: () => import(/* webpackChunkName: "recover-access/new" */ '@/views/RecoverAccess/new.vue'),
    meta: { accessRoute: true }
  },
  {
    path: `/invite/:token(${inviteTokenRegex})`,
    name: 'InviteCompleteCreate',
    component: () => import(/* webpackChunkName: "invite/token" */ '@/views/Invite/CompleteCreate.vue'),
    meta: { accessRoute: true }
  },
  {
    path: '/public/users/chief/create',
    name: 'ChiefUserSelfRegister',
    component: () => import(/* webpackChunkName: "public/users/chief/create" */ '@/views/SelfRegister/create.vue'),
    meta: { accessRoute: true, person: 'chief' }
  },
  {
    path: `/public/event/:eventId(${uuidRegex})/voucher/:voucherId(${uuidRegex})/self-register`,
    name: 'EventSubscriberUserSelfRegister',
    component: () => import(/* webpackChunkName: "public/users/subscriber/create" */ '@/views/SelfRegister/ForVoucher.vue'),
    meta: { accessRoute: true, person: 'subscriber' }
  },
  {
    path: `/public/event/:eventId(${uuidRegex})/voucher/:voucherId(${uuidRegex})`,
    name: 'EventVoucher',
    component: () => import(/* webpackChunkName: "public/event/voucher" */ '@/views/EventVoucher/access.vue'),
    meta: { accessRoute: true }
  },
  {
    path: `/public/event/:eventId(${uuidRegex})/buy-tickets/access`,
    name: 'EventBuyTicketsAccess',
    component: () => import(/* webpackChunkName: "public/event/buy-tickets/access" */ '@/views/EventBuyTickets/access.vue'),
    meta: { accessRoute: true }
  },
  {
    path: `/public/event/:eventId(${uuidRegex})/buy-tickets/access/register`,
    name: 'EventBuyTicketsRegister',
    component: () => import(/* webpackChunkName: "public/event/buy-tickets/access/register" */ '@/views/SelfRegister/ForEventTicket.vue'),
    meta: { accessRoute: true }
  },
  {
    path: '/public/events/subscribed/thank-you',
    name: 'EventBuyTicketsThankYou',
    component: () => import(/* webpackChunkName: "events/buy-tickets/thank-you" */ '@/views/SelfRegister/subscribed.vue'),
    meta: { accessRoute: true }
  },
  {
    path: '/public/users/thank-you',
    name: 'UserSelfRegisterThankYou',
    component: () => import(/* webpackChunkName: "public/users/thank-you" */ '@/views/SelfRegister/thank-you.vue'),
    meta: { accessRoute: true }
  }
]
