import usersRoutes from './modules/users'
import accessRoutes from './modules/access'
import servicesRoutes from './modules/services'
import examsRoutes from './modules/exams'
import questionsRoutes from './modules/questions'
import librariesRoutes from './modules/libraries'
import generalRoutes from './modules/general'
import associationsRoutes from './modules/associations'
import takeExamRoutes from './modules/takeExam'
import toolsRoutes from './modules/tools'
import eventsRoutes from './modules/events'
import medicalRoutes from './modules/medical'

import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  ...accessRoutes,
  ...generalRoutes,
  ...usersRoutes,
  ...servicesRoutes,
  ...examsRoutes,
  ...questionsRoutes,
  ...librariesRoutes,
  ...associationsRoutes,
  ...takeExamRoutes,
  ...eventsRoutes,
  ...medicalRoutes,
  ...toolsRoutes,
  { path: '*', redirect: '/' }
]

export default routes
