const uuidRegex = '\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b'

export default [
  {
    path: '/association',
    component: () => import(/* webpackChunkName: "associations" */ '@/layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'AssociationIndex',
        component: () => import(/* webpackChunkName: "associations/index" */ '@/views/Associations/index.vue')
      },
      {
        path: `:id(${uuidRegex})/edit`,
        name: 'AssociationEdit',
        component: () => import(/* webpackChunkName: "associations/edit" */ '@/views/Associations/edit.vue')
      },
      {
        path: 'attachments/create',
        name: 'AssociationAttachmentsCreate',
        component: () => import(/* webpackChunkName: "associations/attachments/create" */ '@/views/Associations/Attachments/create.vue')
      },
      {
        path: `attachments/:id(${uuidRegex})/show`,
        name: 'AssociationAttachmentsShow',
        component: () => import(/* webpackChunkName: "associations/attachments/show" */ '@/views/Associations/Attachments/show.vue')
      },
      {
        path: 'attachments/types',
        name: 'AssociationAttachmentsTypesIndex',
        component: () => import(/* webpackChunkName: "associations/attachments/type/index" */ '@/views/Associations/Attachments/Types/index.vue')
      },
      {
        path: `attachments/types/:id(${uuidRegex})`,
        name: 'AssociationAttachmentsTypesEdit',
        component: () => import(/* webpackChunkName: "associations/attachments/type/edit" */ '@/views/Associations/Attachments/Types/edit.vue')
      }
    ]
  }
]
