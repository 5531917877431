export default Object.freeze({
  dashboard: '/dashboard',
  services: '/services',
  associations: '/association',
  contactus: '/contact-us',
  exams: '/exams',
  'exams.finished': '/exams/finished',
  'exams.model': '/exams/models',
  'exams.draft': '/exams/drafts',
  'exams.scheduled': '/exams/scheduled',
  events: '/events',
  medical: '/medical',
  questions: '/questions',
  'questions.draft': '/questions/drafts',
  'questions.topic': '/questions/topics',
  libraries: '/libraries',
  users: '/users',
  'users.role': '/users/roles',
  tools: '/tools'
})
