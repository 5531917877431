import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import '@/assets/css/carbon.scss'
import 'carbon-components/css/carbon-components.css'
import '@/assets/css/tailwind.css'
import CarbonComponentsVue from '@carbon/vue/src/index'
import { setInteractionMode } from 'vee-validate'
import Moment from 'moment'
import chartsVue from '@carbon/charts-vue'
import '@carbon/charts/styles.css'
import VueRx from 'vue-rx'
import VuejsClipper from 'vuejs-clipper'
import { PluginObject } from 'vue/types/umd'
import { AxiosError, AxiosRequestConfig } from 'axios'

Moment.locale('pt-BR')
Vue.prototype.moment = Moment

const axios = require('axios').default
const token = store.getters['session/state'].token

if (token) {
  axios.defaults.headers.common['Access-Token'] = token
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.data) {
    Object.keys(config.data).map(k => {
      if (typeof config.data[k] === 'string') {
        config.data[k] = config.data[k].trim()
        if (k === 'email') {
          config.data[k] = config.data[k].toLowerCase()
        }
      }
    })
  }

  return config
}, (error: AxiosError) => {
  console.error('InterceptorError:', error)
  return Promise.reject(error)
})

setInteractionMode('eager')

Vue.use(chartsVue)
Vue.use(CarbonComponentsVue)
Vue.use(VueRx)
Vue.use(VuejsClipper as PluginObject<any>, {
  components: {
    clipperFixed: true,
    clipperUpload: true,
    clipperPreview: true
  }
})

Vue.mixin({
  methods: {
    syncTimeout (function1: Function, function2: Function) {
      function1()
      setTimeout(() => function2(), 100)
    }
  }
})

Vue.config.productionTip = false

Vue.filter('sexString', function (value: string) {
  if (value === 'm') return 'Masculino'
  else if (value === 'f') return 'Feminino'
  else return value
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
