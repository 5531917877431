import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import moment from 'moment'

type takeExamsState = {
  exam: Exam | null;
  score: number;
  availableOn: string;
  scheduledTo: string;
  availableUp: string;
  leftWindowCounter: number;
  examCountdown: number;
  examStatus: string;
  startedOn: string;
  question: {
    nextQuestionId: string | null;
    prevQuestionId: string | null;
    order: number;
    points: number;
    question: Question | null;
  };
}

const state: takeExamsState = {
  exam: null,
  score: 0,
  leftWindowCounter: 0,
  examCountdown: 0,
  availableOn: '',
  scheduledTo: '',
  availableUp: '',
  examStatus: '',
  startedOn: '',
  question: {
    nextQuestionId: null,
    prevQuestionId: null,
    order: 0,
    points: 0,
    question: null
  }
}

async function fetchQuestion (url: string, commit: Function) {
  await axios.get(url)
    .then(async (response) => {
      const responseData = camelCaseKeys(response.data.data, { deep: true })
      await commit('SET_QUESTION', responseData)
    })
    .catch(async (err) => {
      const status = err.response.status

      if (status === 403) {
        await commit('SET_STATUS', err.response.data?.data)
      }
    })
}

export default {
  namespaced: true,
  state,
  getters: {
    getQuestion: (state: takeExamsState) => {
      return state.question
    },
    getExam: (state: takeExamsState) => {
      return state.exam
    },
    getLeftWindowCounter: (state: takeExamsState) => {
      return state.leftWindowCounter
    },
    getExamCountdown: (state: takeExamsState) => {
      return state.examCountdown
    },
    getExamScore: (state: takeExamsState) => {
      return state.score
    },
    getExamTimes: (state: takeExamsState) => {
      const { availableOn, scheduledTo, availableUp } = state

      return { availableOn, scheduledTo, availableUp }
    },
    getExamStatus: (state: takeExamsState) => {
      return state.examStatus
    },
    getExamStartedOn: (state: takeExamsState) => {
      return state.startedOn
    }
  },
  mutations: {
    SET_QUESTION (state: takeExamsState, question: any) {
      state.question = question

      if (state.exam) {
        const examMaxTime = moment(state.scheduledTo).add(state.exam.timeMax, 'minutes')
        state.examCountdown = examMaxTime.diff(moment(), 'seconds')
      }
    },
    SET_EXAM (state: takeExamsState, examSchedule: ExamSchedule) {
      state.exam = examSchedule.exam
      state.availableOn = examSchedule.availableOn
      state.scheduledTo = examSchedule.scheduledTo
      state.availableUp = examSchedule.availableUp
    },
    INCREMENT_LEFT_WINDOW (state: takeExamsState) {
      state.leftWindowCounter++
    },
    SET_LEFT_WINDOW (state: takeExamsState, value: number) {
      state.leftWindowCounter = value
    },
    DECREMENT_EXAM_TIMER (state: takeExamsState) {
      state.examCountdown--
    },
    SET_EXAM_SCORE (state: takeExamsState, value: number) {
      state.score = value
    },
    SET_STATUS (state: takeExamsState, status: string) {
      state.examStatus = status
    },
    SET_EXAM_STARTED_ON (state: takeExamsState, startedOn: string) {
      state.startedOn = startedOn
    }
  },
  actions: {
    async fetchFirstQuestion ({ commit, state }: {commit: Function; state: takeExamsState}, examScheduleServiceUserId: string) {
      const url = `user/${examScheduleServiceUserId}/exam/${state.exam?.id}/start`
      await fetchQuestion(url, commit)
    },
    async fetchNextQuestion ({ commit, state }: {commit: Function; state: takeExamsState}, examScheduleServiceUserId: string) {
      const url = `user/${examScheduleServiceUserId}/exam/${state.exam?.id}/question/${state.question.nextQuestionId}`
      await fetchQuestion(url, commit)
    },
    async fetchPrevQuestion ({ commit, state }: {commit: Function; state: takeExamsState}, examScheduleServiceUserId: string) {
      const url = `user/${examScheduleServiceUserId}/exam/${state.exam?.id}/question/${state.question.prevQuestionId}`
      await fetchQuestion(url, commit)
    },
    async setExamParams ({ commit }: {commit: Function}, exam: ServiceExamSchedule) {
      await commit('SET_EXAM', exam.examSchedule)
      await commit('SET_EXAM_STARTED_ON', exam.startedOn)
    },
    async incrementLeftWindow ({ commit }: {commit: Function}) {
      await commit('INCREMENT_LEFT_WINDOW')
    },
    async setLeftWindow ({ commit }: {commit: Function}, value: number) {
      await commit('SET_LEFT_WINDOW', value)
    },
    async decrementExamCountdown ({ commit }: {commit: Function}) {
      await commit('DECREMENT_EXAM_TIMER')
    },
    async setExamScore ({ commit }: {commit: Function}, value: number) {
      await commit('SET_EXAM_SCORE', value)
    }
  }
}
