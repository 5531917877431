const state: flashMessageState = {
  message: '',
  type: 'success',
  done: true,
  timeoutId: 0
}

export default {
  namespaced: true,
  state,
  getters: {
    getFlashMessage: (state: flashMessageState) => {
      return state
    }
  },
  mutations: {
    DOING (state: flashMessageState) {
      state.done = false
    },
    DONE (state: flashMessageState) {
      state.done = true
    },
    SET_MESSAGE (state: flashMessageState, { message, type = 'success' }: flashMessage) {
      state.message = message
      state.type = type
    },
    SET_TIMEOUT_ID (state: flashMessageState, id: number) {
      state.timeoutId = id
    },
    REMOVE_TIMEOUT (state: flashMessageState) {
      window.clearTimeout(state.timeoutId)
    }
  },
  actions: {
    setMessage ({ commit }: {commit: Function}, messageObj?: flashMessage) {
      commit('DOING')

      if (messageObj) {
        commit('SET_MESSAGE', messageObj)
        commit('REMOVE_TIMEOUT')
        if (!messageObj.noTimeout) {
          const timeout = messageObj.timeout ? messageObj.timeout : 8000
          const id = setTimeout(() => commit('SET_MESSAGE', '', false), timeout)
          commit('SET_TIMEOUT_ID', id)
        }
      } else {
        commit('SET_MESSAGE', '')
      }

      commit('DONE')
    }
  }
}
