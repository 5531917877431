







































import { Component, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import { namespace } from 'vuex-class'

const sessionModule = namespace('session')

type Theme = 'carbon-g10' | 'carbon-g90' | 'carbon-g100' | 'white'

@Component
export default class Components extends Vue {
  @sessionModule.Action('logout') logoutAction!: Function
  @sessionModule.Action('setTEOT') setTEOTAction!: Function
  @sessionModule.Getter isAuthenticated!: Function
  theme: Theme = 'white'

  pingEvent = 0

  // created () {
  // const body = document.body

  // axios.get('/configuracoes')
  //   .then((response: Record<string, any>) => {
  //     this.theme = response.data.theme
  //   }).catch(() => {
  //     this.theme = 'white'
  //   })

  // body.classList.add(this.theme)
  // }

  @Watch('isAuthenticated', { immediate: true })
  onIsAuthenticadedChange (newValue: boolean) {
    if (newValue) {
      this.pingEvent = setInterval(() => {
        axios.get('/token/ping')
          .then(response => {
            this.setTEOTAction(response.data.data?.teot)
          })
          .catch(() => {
            this.logoutAction()
              .finally(() => {
                this.$router.push({ name: 'Login' })
                clearInterval(this.pingEvent)
              })
          })
      }, 60000)
    } else {
      clearInterval(this.pingEvent)
    }
  }

  // onChangeTheme () {
  //   const body = document.body
  //   body.className = ''
  //   body.classList.add(this.theme)
  // }
}
