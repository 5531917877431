import axios from 'axios'

type permissionsState = {
  done: boolean;
  error: string;
  success: boolean;
  permissions: UserPermissions;
}

type UserPermission = {
  id: string;
  name: string;
}

export default {
  namespaced: true,
  state: {
    permissions: [],
    error: '',
    done: true,
    success: true
  },
  getters: {
    hasPermission: (state: permissionsState) => (name: string) => {
      return Boolean(state.permissions.find(permission => permission.name === name))
    }
  },
  mutations: {
    DOING (state: permissionsState) {
      state.done = false
      state.error = ''
    },
    DONE (state: permissionsState) {
      state.done = true
    },
    ERROR (state: permissionsState, error: string) {
      state.success = false
      state.error = error
    },
    SET_PERMISSIONS (state: permissionsState, permissions: Array<UserPermission>) {
      state.permissions = permissions
    }
  },
  actions: {
    async fetchPermissions ({ commit }: {commit: Function}, userId: string) {
      commit('DOING')
      await axios.get(`user/${userId}/roles/with-permissions`)
        .then(async response => {
          const permissions = response.data.data
            .reduce((acc: UserPermissions, permission: UserRoleWithPermissions) => {
              acc.push(...permission.permissions)
              return acc
            }, [])

          await commit('SET_PERMISSIONS', permissions)
        })
        .catch(error => {
          console.log(error.response)
          commit('ERROR', error.response.data.error)
        })
        .finally(() => commit('DONE'))
    }
  }
}
