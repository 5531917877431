import store from '@/store'
import navbarRoute from '@/helpers/navbar'

export default [
  {
    path: '',
    component: () => import(/* webpackChunkName: "components" */ '@/layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        redirect: () => {
          const navbarItems: Array<NavbarItem> = store.getters['session/getNavbarItems']
          const isChiefWithoutService = store.getters['session/isChiefWithoutService']
          const isAuthenticated = store.getters['session/isAuthenticated']

          if (navbarItems.length === 0) {
            if (isChiefWithoutService) {
              return { name: 'ServicesPublicCreate' }
            } else if (!isAuthenticated) {
              return { name: 'Login' }
            } else {
              return { name: 'ProfileSettings' }
            }
          } else {
            return navbarRoute[navbarItems[0].key as keyof typeof navbarRoute]
          }
        }
      },
      {
        path: 'contact-us',
        name: 'ContactUs',
        component: () => import(/* webpackChunkName: "contact-us" */ '@/views/ContactUs.vue')
      },
      {
        path: 'profile',
        name: 'ProfileSettings',
        component: () => import(/* webpackChunkName: "profile/index" */ '@/views/ProfileSettings.vue')
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },
  {
    path: '/charts',
    name: 'Charts',
    component: () => import(/* webpackChunkName: "charts" */ '@/views/ChartsMock.vue')
  },
  {
    path: '/components',
    name: 'Componentes',
    component: () => import(/* webpackChunkName: "components" */ '@/views/Components.vue')
  }
]
