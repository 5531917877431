import { RouteConfig } from 'vue-router'

const uuidRegex = '\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b'

const routes: Array<RouteConfig> = [
  {
    path: '/events',
    component: () => import(/* webpackChunkName: "events" */ '@/layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'Events',
        component: () => import(/* webpackChunkName: "events/index" */ '@/views/Events/index.vue')
      },
      {
        path: 'create',
        name: 'EventCreate',
        component: () => import(/* webpackChunkName: "events/create" */ '@/views/Events/create.vue')
      },
      {
        path: 'buy-tickets/thank-you',
        name: 'EventBuyTicketsThankYou',
        component: () => import(/* webpackChunkName: "events/buy-tickets/thank-you" */ '@/views/EventBuyTickets/thank-you.vue')
      },
      {
        path: `:id(${uuidRegex})`,
        component: () => import(/* webpackChunkName: "events/layout" */ '@/layouts/EventsShowMenu.vue'),
        children: [
          {
            path: '',
            name: 'EventShow',
            component: () => import(/* webpackChunkName: "events/show" */ '@/views/Events/show.vue')
          },
          {
            path: 'activities',
            name: 'EventActivities',
            component: () => import(/* webpackChunkName: "events/activities/index" */ '@/views/Events/Activities/index.vue')
          },
          {
            path: 'schedules',
            name: 'EventSchedules',
            component: () => import(/* webpackChunkName: "events/schedules/index" */ '@/views/Events/Schedules/index.vue')
          },
          {
            path: 'studies',
            name: 'EventStudies',
            component: () => import(/* webpackChunkName: "events/studies/index" */ '@/views/Events/Studies/index.vue')
          },
          {
            path: 'studies/grades',
            name: 'EventStudiesGrade',
            component: () => import(/* webpackChunkName: "events/studies/grades" */ '@/views/Events/Studies/grades.vue')
          },
          {
            path: 'speakers',
            name: 'EventSpeakers',
            component: () => import(/* webpackChunkName: "events/speakers/index" */ '@/views/Events/Speakers/index.vue')
          },
          {
            path: 'subscriptions',
            name: 'EventSubscriptions',
            component: () => import(/* webpackChunkName: "events/subscriptions/index" */ '@/views/Events/Subscriptions/index.vue')
          },
          {
            path: 'sponsors',
            name: 'EventSponsors',
            component: () => import(/* webpackChunkName: "events/sponsors/index" */ '@/views/Events/Sponsors/index.vue')
          },
          {
            path: 'staff',
            name: 'EventStaff',
            component: () => import(/* webpackChunkName: "events/staff/index" */ '@/views/Events/Staff/index.vue')
          },
          {
            path: 'subscribers',
            name: 'EventSubscribers',
            component: () => import(/* webpackChunkName: "events/subscribers/index" */ '@/views/Events/Subscribers/index.vue')
          }
        ]
      },
      {
        path: `:id(${uuidRegex})`,
        component: () => import(/* webpackChunkName: "events/overview/layout" */ '@/layouts/events/OverviewLayout.vue'),
        children: [
          {
            path: 'edit',
            name: 'EventEdit',
            component: () => import(/* webpackChunkName: "events/edit" */ '@/views/Events/edit.vue')
          },
          {
            path: 'topics',
            name: 'EventTopics',
            component: () => import(/* webpackChunkName: "events/topics" */ '@/views/Events/Topics/index.vue')
          },
          {
            path: 'subscription-types',
            name: 'EventSubscriptionTypes',
            component: () => import(/* webpackChunkName: "events/subscription-types" */ '@/views/Events/Subscriptions/Types/index.vue')
          },
          {
            path: 'rooms',
            name: 'EventRooms',
            component: () => import(/* webpackChunkName: "events/rooms" */ '@/views/Events/Rooms/index.vue')
          },
          {
            path: 'study-rules',
            name: 'EventStudyRules',
            component: () => import(/* webpackChunkName: "events/study-rules" */ '@/views/Events/StudyRules/index.vue')
          },
          {
            path: 'subscriptions/history',
            name: 'EventSubscriptionsHistory',
            component: () => import(/* webpackChunkName: "events/subscriptions/history" */ '@/views/Events/Subscriptions/History/index.vue')
          },
          {
            path: 'study-reviewers',
            name: 'EventStudyReviewers',
            component: () => import(/* webpackChunkName: "events/study-reviewers/index" */ '@/views/Events/StudyReviewers/index.vue')
          },
          {
            path: 'study-review-types',
            name: 'EventStudyReviewTypes',
            component: () => import(/* webpackChunkName: "events/study-review-types/index" */ '@/views/Events/StudyReviewTypes/index.vue')
          },
          {
            path: 'buy-tickets',
            name: 'EventBuyTickets',
            component: () => import(/* webpackChunkName: "events/buy-tickets" */ '@/views/EventBuyTickets/index.vue')
          },
          {
            path: 'organizers',
            name: 'EventOrganizers',
            component: () => import(/* webpackChunkName: "events/organizers/index" */ '@/views/Events/Organizers/index.vue')
          },
          {
            path: 'activity-modules',
            name: 'EventActivityModules',
            component: () => import(/* webpackChunkName: "events/activity-modules/index" */ '@/views/Events/ActivityModules/index.vue')
          },
          {
            path: `subscription/:subscriptionId(${uuidRegex})/attachments/create`,
            name: 'EventSubscriptionAttachmentsCreate',
            component: () => import(/* webpackChunkName: "events/subscription/attachments/create" */ '@/views/Events/Subscriptions/Attachments/create.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/subscription-types`,
        component: () => import(/* webpackChunkName: "events/subscription-types/layout" */ '@/layouts/events/SubscriptionTypesLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'EventSubscriptionTypeCreate',
            component: () => import(/* webpackChunkName: "events/subscription-types/create" */ '@/views/Events/Subscriptions/Types/create.vue')
          },
          {
            path: `:subscriptionId(${uuidRegex})/edit`,
            name: 'EventSubscriptionTypeEdit',
            component: () => import(/* webpackChunkName: "events/subscription-types/edit" */ '@/views/Events/Subscriptions/Types/edit.vue')
          },
          {
            path: `:subscriptionId(${uuidRegex})/items`,
            name: 'EventSubscriptionItems',
            component: () => import(/* webpackChunkName: "events/subscription-types/items" */ '@/views/Events/Subscriptions/Items/index.vue')
          },
          {
            path: `:subscriptionId(${uuidRegex})/rules`,
            name: 'EventSubscriptionRules',
            component: () => import(/* webpackChunkName: "events/subscription-types/rules" */ '@/views/Events/Subscriptions/Rules/index.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/studies`,
        component: () => import(/* webpackChunkName: "events/studies/layout" */ '@/layouts/events/StudyLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'EventStudyCreate',
            component: () => import(/* webpackChunkName: "events/studies/create" */ '@/views/Events/Studies/create.vue')
          },
          {
            path: `:studyId(${uuidRegex})/edit`,
            name: 'EventStudyEdit',
            component: () => import(/* webpackChunkName: "events/studies/edit" */ '@/views/Events/Studies/edit.vue')
          },
          {
            path: `:studyId(${uuidRegex})/show`,
            name: 'EventStudyShow',
            component: () => import(/* webpackChunkName: "events/studies/show" */ '@/views/Events/Studies/show.vue')
          },
          {
            path: `:studyId(${uuidRegex})/reviews`,
            name: 'EventStudyReviews',
            component: () => import(/* webpackChunkName: "events/studies/reviews" */ '@/views/Events/StudyReviews/index.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/study-review-types`,
        component: () => import(/* webpackChunkName: "events/study-review-types/layout" */ '@/layouts/events/StudyReviewTypeLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'EventStudyReviewTypeCreate',
            component: () => import(/* webpackChunkName: "events/study-review-types/create" */ '@/views/Events/StudyReviewTypes/create.vue')
          },
          {
            path: `:reviewTypeId(${uuidRegex})/edit`,
            name: 'EventStudyReviewTypeEdit',
            component: () => import(/* webpackChunkName: "events/study-review-types/edit" */ '@/views/Events/StudyReviewTypes/edit.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/study-review-types/:reviewTypeId(${uuidRegex})/criteria`,
        component: () => import(/* webpackChunkName: "events/study-review-types/layout" */ '@/layouts/events/StudyCriteriaLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'EventStudyCriterionCreate',
            component: () => import(/* webpackChunkName: "events/study-criteria/create" */ '@/views/Events/StudyCriteria/create.vue')
          },
          {
            path: `:studyCriterionId(${uuidRegex})/edit`,
            name: 'EventStudyCriterionEdit',
            component: () => import(/* webpackChunkName: "events/study-criteria/edit" */ '@/views/Events/StudyCriteria/edit.vue'),
            meta: { person: 'studyReviewer' }
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/schedules`,
        component: () => import(/* webpackChunkName: "events/schedules/layout" */ '@/layouts/events/SchedulesLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'EventScheduleCreate',
            component: () => import(/* webpackChunkName: "events/schedules/create" */ '@/views/Events/Schedules/create.vue')
          },
          {
            path: `:scheduleId(${uuidRegex})/edit`,
            name: 'EventScheduleEdit',
            component: () => import(/* webpackChunkName: "events/schedules/edit" */ '@/views/Events/Schedules/edit.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/speakers`,
        component: () => import(/* webpackChunkName: "events/schedules/layout" */ '@/layouts/events/SpeakersLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'EventSpeakerCreate',
            component: () => import(/* webpackChunkName: "events/schedules/create" */ '@/views/Events/Speakers/create.vue')
          },
          {
            path: `:speakerId(${uuidRegex})/edit`,
            name: 'EventSpeakerEdit',
            component: () => import(/* webpackChunkName: "events/schedules/edit" */ '@/views/Events/Speakers/edit.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/study-reviewer`,
        component: () => import(/* webpackChunkName: "events/study-reviewer/layout" */ '@/layouts/events/StudyReviewerLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'EventStudyReviewerCreate',
            component: () => import(/* webpackChunkName: "events/study-reviewer/create" */ '@/views/Events/StudyReviewers/create.vue')
          },
          {
            path: `:studyReviewerId(${uuidRegex})/edit`,
            name: 'EventStudyReviewerEdit',
            component: () => import(/* webpackChunkName: "events/study-reviewer/edit" */ '@/views/Events/StudyReviewers/edit.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/studies/:studyId(${uuidRegex})`,
        component: () => import(/* webpackChunkName: "events/studies/layout" */ '@/layouts/events/StudyReviewLayout.vue'),
        children: [
          {
            path: `review-types/:reviewTypeId(${uuidRegex})/reviews/create`,
            name: 'EventStudyReviewCreate',
            component: () => import(/* webpackChunkName: "events/studies/reviews/create" */ '@/views/Events/StudyReviews/create.vue')
          },
          {
            path: `reviews/:reviewId(${uuidRegex})`,
            name: 'EventStudyReviewShow',
            component: () => import(/* webpackChunkName: "events/studies/reviews/show" */ '@/views/Events/StudyReviews/show.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/study-rules`,
        component: () => import(/* webpackChunkName: "events/study-rules/layout" */ '@/layouts/events/StudyRulesLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'EventStudyRuleCreate',
            component: () => import(/* webpackChunkName: "events/study-rules/create" */ '@/views/Events/StudyRules/create.vue')
          },
          {
            path: `:studyRuleId(${uuidRegex})/edit`,
            name: 'EventStudyRuleEdit',
            component: () => import(/* webpackChunkName: "events/study-rules/edit" */ '@/views/Events/StudyRules/edit.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/subscriptions`,
        component: () => import(/* webpackChunkName: "events/subscriptions/layout" */ '@/layouts/events/SubscriptionsLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'EventSubscriptionCreate',
            component: () => import(/* webpackChunkName: "events/subscriptions/create" */ '@/views/Events/Subscriptions/create.vue')
          },
          {
            path: `:subscriptionId(${uuidRegex})/edit`,
            name: 'EventSubscriptionEdit',
            component: () => import(/* webpackChunkName: "events/subscriptions/edit" */ '@/views/Events/Subscriptions/edit.vue')
          },
          {
            path: `:subscriptionId(${uuidRegex})/operations`,
            name: 'EventSubscriptionOperations',
            component: () => import(/* webpackChunkName: "events/subscriptions/operations" */ '@/views/Events/Subscriptions/operations.vue')
          },
          {
            path: 'batch/create',
            name: 'EventSubscriptionBatchCreate',
            component: () => import(/* webpackChunkName: "events/subscriptions/batch/create" */ '@/views/Events/Subscriptions/Batch/create.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/subscriptions/:subscriptionId(${uuidRegex})/history`,
        name: 'EventSubscriptionsHistoryIndividual',
        component: () => import(/* webpackChunkName: "events/subscriptions/individual/history" */ '@/views/Events/Subscriptions/History/individual.vue')
      },
      {
        path: `:eventId(${uuidRegex})/subscriptions/:subscriptionId(${uuidRegex})/items`,
        component: () => import(/* webpackChunkName: "events/subscriptions/layout" */ '@/layouts/events/SubscriptionItemsLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'EventSubscriptionItemsCreate',
            component: () => import(/* webpackChunkName: "events/subscriptions/items/create" */ '@/views/Events/Subscriptions/Items/create.vue')
          },
          {
            path: `:subscriptionTypeItemId(${uuidRegex})/edit`,
            name: 'EventSubscriptionItemsEdit',
            component: () => import(/* webpackChunkName: "events/subscriptions/items/edit" */ '@/views/Events/Subscriptions/Items/edit.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/subscriptions/:subscriptionId(${uuidRegex})/rules`,
        component: () => import(/* webpackChunkName: "events/subscriptions/layout" */ '@/layouts/events/SubscriptionRulesLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'EventSubscriptionRulesCreate',
            component: () => import(/* webpackChunkName: "events/subscriptions/rules/create" */ '@/views/Events/Subscriptions/Rules/create.vue')
          },
          {
            path: `:subscriptionTypeRuleId(${uuidRegex})/rules`,
            name: 'EventSubscriptionRulesEdit',
            component: () => import(/* webpackChunkName: "events/subscriptions/rules/edit" */ '@/views/Events/Subscriptions/Rules/edit.vue')
          }
        ]
      },
      // {
      //   path: `:eventId(${uuidRegex})/event-user-speakers/`,
      //   component: () => import(/* webpackChunkName: "events/speakers/layout" */ '@/layouts/events/SpeakersLayout (EventUser).vue'),
      //   children: [
      //     {
      //       path: `:speakerId(${uuidRegex})/edit`,
      //       name: 'EventSpeakerEdit',
      //       component: () => import(/* webpackChunkName: "events/speakers/edit" */ '@/views/Events/Speakers/eventuser-edit.vue')
      //     },
      //     {
      //       path: `:speakerId(${uuidRegex})/activities/create`,
      //       name: 'EventSpeakerActivityCreate',
      //       component: () => import(/* webpackChunkName: "events/speakers/activities/create" */ '@/views/Events/Activities/create.vue')
      //     },
      //     {
      //       path: `:speakerId(${uuidRegex})/activities/:activityId(${uuidRegex})/edit`,
      //       name: 'EventSpeakerActivityEdit',
      //       component: () => import(/* webpackChunkName: "events/speakers/activities/create" */ '@/views/Events/Activities/eventuser-edit.vue')
      //     },
      //     {
      //       path: 'add-person',
      //       name: 'AddSpeakerToEvent',
      //       component: () => import(/* webpackChunkName: "events/addPerson/speaker" */ '@/views/Events/AddPerson/Speaker.vue')
      //     },
      //     {
      //       path: 'add-person/create',
      //       name: 'CreateSpeakerToEvent',
      //       component: () => import(/* webpackChunkName: "events/addPerson/speaker/create" */ '@/views/Events/AddPerson/CreateUserToEvent.vue'),
      //       meta: { person: 'speaker' }
      //     }
      //   ]
      // },
      {
        path: `:eventId(${uuidRegex})/staff/`,
        component: () => import(/* webpackChunkName: "events/staff/layout" */ '@/layouts/events/StaffLayout.vue'),
        children: [
          {
            path: `:staffId(${uuidRegex})/activities/create`,
            name: 'EventStaffActivityCreate',
            component: () => import(/* webpackChunkName: "events/staff/activities/create" */ '@/views/Events/Activities/create.vue')
          },
          {
            path: 'add-person',
            name: 'AddStaffToEvent',
            component: () => import(/* webpackChunkName: "events/addPerson/staff" */ '@/views/Events/AddPerson/Staff.vue')
          },
          {
            path: 'add-person/create',
            name: 'CreateStaffToEvent',
            component: () => import(/* webpackChunkName: "events/addPerson/speaker/create" */ '@/views/Events/AddPerson/CreateUserToEvent.vue'),
            meta: { person: 'staff' }
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/sponsors`,
        component: () => import(/* webpackChunkName: "events/speakers/layout" */ '@/layouts/events/SponsorsLayout.vue'),
        children: [
          {
            path: `:eventSponsorId(${uuidRegex})/edit`,
            name: 'EventSponsorEdit',
            component: () => import(/* webpackChunkName: "events/sponsors/edit" */ '@/views/Events/Sponsors/edit.vue')
          },
          {
            path: 'add',
            name: 'AddSponsorToEvent',
            component: () => import(/* webpackChunkName: "events/addPerson/sponsor" */ '@/views/Events/Sponsors/create.vue')
          },
          {
            path: 'add-person/create',
            name: 'CreateSponsorToEvent',
            component: () => import(/* webpackChunkName: "events/addPerson/sponsor/create" */ '@/views/Events/AddPerson/CreateUserToEvent.vue'),
            meta: { person: 'sponsor' }
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/subscribers`,
        component: () => import(/* webpackChunkName: "events/subscribers/layout" */ '@/layouts/events/SubscribersLayout.vue'),
        children: [
          {
            path: 'add-person',
            name: 'AddSubscriberToEvent',
            component: () => import(/* webpackChunkName: "events/addPerson/subscriber" */ '@/views/Events/AddPerson/Subscriber.vue')
          },
          {
            path: 'add-person/create',
            name: 'CreateSubscriberToEvent',
            component: () => import(/* webpackChunkName: "events/addPerson/subscriber/create" */ '@/views/Events/AddPerson/CreateUserToEvent.vue'),
            meta: { person: 'subscriber' }
          }

        ]
      },
      {
        path: `:eventId(${uuidRegex})/subscriptions`,
        component: () => import(/* webpackChunkName: "events/subscribers/layout" */ '@/layouts/events/SubscribersLayout.vue'),
        children: [
          {
            path: `:subscriptionId(${uuidRegex})/exams-payment`,
            name: 'EventExamPayments',
            component: () => import(/* webpackChunkName: "events/subscriptions/e" */ '@/views/Events/Subscribers/ExamPaymentAttempts.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/activities/`,
        component: () => import(/* webpackChunkName: "events/activities/layout" */ '@/layouts/events/ActivitiesLayout.vue'),
        children: [
          {
            path: `:activityId(${uuidRegex})/edit`,
            name: 'EventActivityEdit',
            component: () => import(/* webpackChunkName: "events/activities/edit" */ '@/views/Events/Activities/edit.vue')
          },
          {
            path: 'create',
            name: 'EventActivityCreate',
            component: () => import(/* webpackChunkName: "events/activities/create" */ '@/views/Events/Activities/create.vue')
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/organizers`,
        component: () => import(/* webpackChunkName: "events/organizers/layout" */ '@/layouts/events/OrganizersLayout.vue'),
        children: [
          {
            path: 'add-person',
            name: 'AddOrganizerToEvent',
            component: () => import(/* webpackChunkName: "events/addPerson/organizer" */ '@/views/Events/AddPerson/Organizer.vue')
          },
          {
            path: 'add-person/create',
            name: 'CreateOrganizerToEvent',
            component: () => import(/* webpackChunkName: "events/addPerson/organizer/create" */ '@/views/Events/AddPerson/CreateUserToEvent.vue'),
            meta: { person: 'organizer' }
          }
        ]
      },
      {
        path: `:eventId(${uuidRegex})/activities/:activityId(${uuidRegex})/attachments`,
        component: () => import(/* webpackChunkName: "events/activities/attachments/layout" */ '@/layouts/events/ActivityAttachmentsLayout.vue'),
        children: [
          {
            path: 'create',
            name: 'EventActivityAttachmentCreate',
            component: () => import(/* webpackChunkName: "events/activities/attachments/create" */ '@/views/Events/Activities/Attachments/create.vue')
          },
          {
            path: `:attachmentId(${uuidRegex})/edit`,
            name: 'EventActivityAttachmentEdit',
            component: () => import(/* webpackChunkName: "events/activities/attachments/edit" */ '@/views/Events/Activities/Attachments/edit.vue')
          }
        ]
      }
    ]
  },
  {
    path: `/events/:eventId(${uuidRegex})/subscriptions/:subscriptionId(${uuidRegex})/finish-and-print`,
    name: 'EventVoucherFinishAndPrint',
    component: () => import(/* webpackChunkName: "events/" */ '@/views/EventVoucher/FinishAndPrint.vue')
  }
]

export default routes
