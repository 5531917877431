import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import session from './modules/session'
import userPermissions from './modules/userPermissions'
import servicesPermissions from './modules/servicesPermissions'
import eventsPermissions from './modules/eventsPermissions'
import flashMessage from './modules/flashMessage'
import takeExam from './modules/takeExam'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    session,
    userPermissions,
    servicesPermissions,
    eventsPermissions,
    flashMessage,
    takeExam
  },
  plugins: [createPersistedState({
    key: 'sbq',
    paths: [
      'session.user',
      'session.token',
      'session.interface',
      'userPermissions.permissions',
      'servicesPermissions.services',
      'eventsPermissions.events',
      'eventsPermissions.studies',
      'takeExam'
    ]
  })]
})
