import permissions from '@/helpers/permissions'
const uuidRegex = '\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b'

export default [
  {
    path: '/questions',
    component: () => import(/* webpackChunkName: "questions" */ '@/layouts/Main.vue'),
    children: [
      {
        path: 'create',
        name: 'QuestionsCreate',
        component: () => import(/* webpackChunkName: "questions/create" */ '@/views/Questions/create.vue'),
        meta: { permissions: [permissions.QUESTIONDRAFT.CREATE] }
      },
      {
        path: `drafts/:id(${uuidRegex})/edit`,
        name: 'QuestionsDraftsEdit',
        component: () => import(/* webpackChunkName: "questions/drafts/edit" */ '@/views/Questions/Drafts/edit.vue'),
        meta: { permissions: [permissions.QUESTIONDRAFT.CREATE] }
      },
      {
        path: `:id(${uuidRegex})`,
        name: 'QuestionsShow',
        component: () => import(/* webpackChunkName: "questions/show" */ '@/views/Questions/show.vue'),
        meta: { permissions: [permissions.QUESTION.READ] }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "questions/layout" */ '@/layouts/QuestionsMenu.vue'),
        children: [
          {
            path: '',
            name: 'Questions',
            component: () => import(/* webpackChunkName: "questions/index" */ '@/views/Questions/index.vue'),
            meta: { permissions: [permissions.QUESTION.READ] }
          },
          {
            path: 'topics',
            name: 'QuestionsTopics',
            component: () => import(/* webpackChunkName: "questions/topics" */ '@/views/Questions/Topics/index.vue'),
            meta: { permissions: [permissions.TOPIC.READ] }
          },
          {
            path: 'drafts',
            name: 'QuestionsDrafts',
            component: () => import(/* webpackChunkName: "questions/drafts" */ '@/views/Questions/Drafts/index.vue'),
            meta: { permissions: [permissions.QUESTIONDRAFT.READ] }
          }
        ]
      }
    ]
  }
]
