type permissionsState = {
  done: boolean;
  error: string;
  success: boolean;
  events: Array<EventWithPermissions>;
  studies: Array<EventWithStudyPermissions> | boolean;
}

const state: permissionsState = {
  events: [],
  studies: [],
  error: '',
  done: true,
  success: true
}

function convertEventRolesFromSession (sessionEventRoles: Array<EventRoleWithPermissions>) {
  return sessionEventRoles.map((eventRole: EventRoleWithPermissions) => {
    const event = {
      id: eventRole.eventId,
      permissions: eventRole.rolePermissions,
      eventUserId: eventRole.eventUserId
    }

    return event
  })
}
export default {
  namespaced: true,
  state,
  getters: {
    hasPermission: (state: permissionsState, getters: Function, rootState: Function, rootGetters: Record<string, Function>) => (eventId: string, permissionName: string) => {
      if (!rootGetters['session/isEventPresident']) {
        const events = state.events.filter(event => eventId === event.id)

        if (events.length === 0) {
          return false
        } else {
          return Boolean(events.find(event => event.permissions.find(permission => permissionName === permission.name)))
        }
      } else {
        return rootGetters['userPermissions/hasPermission'](permissionName)
      }
    },
    hasStudyPermission: (state: permissionsState) => (eventId: string, permissionType: 'create' | 'update') => {
      if (state.studies === true) {
        return true
      } else {
        if (typeof state.studies !== 'boolean') {
          const study = state.studies.find(study => study.eventId === eventId)
          return Boolean(study?.[permissionType])
        }
      }
      return false
    },
    isSubscriberOnEvent: (state: permissionsState) => (eventId: string) => {
      const event = state.events.find(event => eventId === event.id)

      return !!event
    },
    getEventUserId: (state: permissionsState) => (eventId: string) => {
      const event = state.events.find(event => eventId === event.id)

      if (!event) {
        return false
      } else {
        return event.eventUserId
      }
    }
  },
  mutations: {
    DOING (state: permissionsState) {
      state.done = false
      state.error = ''
    },
    DONE (state: permissionsState) {
      state.done = true
    },
    ERROR (state: permissionsState, error: string) {
      state.success = false
      state.error = error
    },
    SET_PERMISSIONS (state: permissionsState, events: Array<EventWithPermissions>) {
      state.events = events
    },
    SET_STUDY_PERMISSIONS (state: permissionsState, studies: Array<EventWithStudyPermissions>) {
      state.studies = studies
    },
    ADD_PERMISSIONS (state: permissionsState, event: EventWithPermissions) {
      const stateEvents = state.events.find(stateEvent => stateEvent.id === event.id)

      if (!stateEvents) {
        state.events.push(event)
      }
    }
  },
  actions: {
    setPermissionsFromSession ({ commit }: {commit: Function}, eventsRoles: Array<EventRoleWithPermissions>) {
      const events = convertEventRolesFromSession(eventsRoles)
      commit('SET_PERMISSIONS', events)
    },
    setStudyPermissions ({ commit }: {commit: Function}, studyPermissions: Array<EventWithStudyPermissions>) {
      commit('SET_STUDY_PERMISSIONS', studyPermissions)
    }
  }
}
