export default [
  {
    path: '/tools',
    component: () => import(/* webpackChunkName: "tools" */ '@/layouts/Main.vue'),
    children: [
      {
        path: '/',
        name: 'Tools',
        component: () => import(/* webpackChunkName: "tools/index" */ '@/views/Tools/index.vue')
      }
    ]
  }
]
