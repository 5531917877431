import permissions from '@/helpers/permissions'

const uuidRegex = '\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b'

export default [
  {
    path: '/users',
    component: () => import(/* webpackChunkName: "users" */ '@/layouts/Main.vue'),
    children: [
      {
        path: `roles/:id(${uuidRegex})/edit`,
        name: 'UsersRolesPermissionsEdit',
        component: () => import(/* webpackChunkName: "users/roles/edit" */ '@/views/Users/Roles/edit.vue'),
        meta: { permissions: [permissions.USER.UPDATE] }
      },
      {
        path: `:id(${uuidRegex})/edit`,
        name: 'UsersEdit',
        component: () => import(/* webpackChunkName: "users/edit" */ '@/views/Users/edit.vue'),
        meta: { permissions: [permissions.USER.UPDATE] }
      },
      {
        path: 'create',
        name: 'UsersCreate',
        component: () => import(/* webpackChunkName: "users/create" */ '@/views/Users/create.vue'),
        meta: { permissions: [permissions.USER.CREATE] }
      },
      {
        path: `:id(${uuidRegex})/audit`,
        name: 'UsersAudit',
        component: () => import(/* webpackChunkName: "users/audit" */ '@/views/Users/audit.vue'),
        meta: { permissions: [permissions.MONITOR.READ] }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "users/layout" */ '@/layouts/UsersMenu.vue'),
        redirect: { name: 'Users' },
        children: [
          {
            path: '',
            name: 'Users',
            component: () => import(/* webpackChunkName: "users/index" */ '@/views/Users/index.vue'),
            meta: { permissions: [permissions.USER.READ] }
          },
          {
            path: 'roles',
            name: 'UsersRoles',
            component: () => import(/* webpackChunkName: "users/roles" */ '@/views/Users/Roles/index.vue'),
            meta: { permissions: [permissions.USERROLE.READ] }
          }
        ]
      }
    ]
  }
]
