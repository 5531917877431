import permissions from '@/helpers/permissions'
const uuidRegex = '\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b'

export default [
  {
    path: '/exams',
    component: () => import(/* webpackChunkName: "exams" */ '@/layouts/Main.vue'),
    children: [
      {
        path: 'scheduled/create',
        name: 'ExamsScheduledCreate',
        component: () => import(/* webpackChunkName: "exams/scheduled/create" */ '@/views/Exams/Scheduled/create.vue'),
        meta: { permissions: [permissions.EXAMSCHEDULE.CREATE] }
      },
      {
        path: 'drafts/create',
        name: 'ExamsDraftsCreate',
        component: () => import(/* webpackChunkName: "exams/drafts/create" */ '@/views/Exams/Drafts/create.vue')
      },
      {
        path: `models/:id(${uuidRegex})/edit`,
        name: 'ExamsModelsEdit',
        component: () => import(/* webpackChunkName: "exams/models/edit" */ '@/views/Exams/Models/edit.vue'),
        meta: { permissions: [permissions.EXAM.UPDATE] }
      },
      {
        path: `drafts/:id(${uuidRegex})/edit`,
        name: 'ExamsDraftsEdit',
        component: () => import(/* webpackChunkName: "exams/drafts/edit" */ '@/views/Exams/Drafts/edit.vue'),
        meta: { permissions: [permissions.EXAMDRAFT.UPDATE] }
      },
      {
        path: `:examId(${uuidRegex})/schedule/:scheduleId(${uuidRegex})/user/:scheduleServiceUserId(${uuidRegex})`,
        name: 'ExamsFinishedReportCandidate',
        component: () => import(/* webpackChunkName: "exams/finished/report/candidate" */ '@/views/Exams/Finished/reportCandidate.vue')
      },
      {
        path: `:examId(${uuidRegex})/schedule/:scheduleId(${uuidRegex})/report`,
        name: 'ExamsFinishedReport',
        component: () => import(/* webpackChunkName: "exams/finished/report" */ '@/views/Exams/Finished/report.vue')
      },
      {
        path: `:examId(${uuidRegex})/schedule/:scheduleId(${uuidRegex})`,
        name: 'ExamsScheduledEdit',
        component: () => import(/* webpackChunkName: "exams/scheduled/index" */ '@/views/Exams/Scheduled/edit.vue'),
        meta: { permissions: [permissions.EXAMSCHEDULE.UPDATE] }
      },
      {
        path: `:examId(${uuidRegex})/schedule/:scheduleId(${uuidRegex})/monitor`,
        name: 'ExamsScheduledMonitor',
        component: () => import(/* webpackChunkName: "exams/scheduled/monitor" */ '@/views/Exams/Scheduled/monitor.vue')
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "exams/layout" */ '@/layouts/ExamsMenu.vue'),
        redirect: { name: 'ExamsFinished' },
        children: [
          {
            path: 'scheduled',
            name: 'ExamsScheduled',
            component: () => import(/* webpackChunkName: "exams/scheduled/index" */ '@/views/Exams/Scheduled/index.vue'),
            meta: { permissions: [permissions.EXAMSCHEDULE.READ] }
          },
          {
            path: 'finished',
            name: 'ExamsFinished',
            component: () => import(/* webpackChunkName: "exams/finished/index" */ '@/views/Exams/Finished/index.vue'),
            meta: { permissions: [permissions.EXAM.READ] }
          },
          {
            path: 'models',
            name: 'ExamsModels',
            component: () => import(/* webpackChunkName: "exams/models/index" */ '@/views/Exams/Models/index.vue'),
            meta: { permissions: [permissions.EXAM.READ] }
          },
          {
            path: 'drafts',
            name: 'ExamsDrafts',
            component: () => import(/* webpackChunkName: "exams/drafts/index" */ '@/views/Exams/Drafts/index.vue'),
            meta: { permissions: [permissions.EXAMDRAFT.READ] }
          }
        ]
      }
    ]
  }
]
