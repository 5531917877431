// import permissions from '@/helpers/permissions'
const uuidRegex = '\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b'

export default [
  {
    path: `/user/:examScheduleServiceUserId(${uuidRegex})/start`,
    name: 'TakeExamStart',
    component: () => import(/* webpackChunkName: "take-exam/start" */ '@/views/TakeExam/start.vue')
  },
  {
    path: `/user/:examScheduleServiceUserId(${uuidRegex})/question/:examQuestionId(${uuidRegex})`,
    name: 'TakeExamQuestion',
    component: () => import(/* webpackChunkName: "take-exam/question" */ '@/views/TakeExam/question.vue')
  },
  {
    path: `/user/:examScheduleServiceUserId(${uuidRegex})/result`,
    name: 'TakeExamResult',
    component: () => import(/* webpackChunkName: "take-exam/result" */ '@/views/TakeExam/result.vue')
  }
]
